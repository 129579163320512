const theme = {
  breakpoints: ["600px"],
  colors: {
    text: "#554740",
    background: "#ffffff",
    primary: "#008200",
    secondary: "#b20000",
    // custom colors
    link: "#b20000",
    visitedLink: "#551a8b",
  },
  fonts: {
    body: 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    heading: 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [
    // h6, h5, h4, h3, h2, h1
    "1rem",
    "1rem",
    "1rem",
    "1.1rem",
    "1.2rem",
    "1.5rem",
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.7,
    heading: 1.3,
    table: 1.5,
  },
  letterSpacings: {
    body: 0.2, // 0.2px
    heading: 0,
  },

  // variants
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      letterSpacing: "heading",
    },
  },

  styles: {
    root: { // styles of the root 'html' element
      color: "text",
      backgroundColor: "background",
      fontFamily: "body",
      fontWeight: "body",
      fontSize: [null, "125%"],
      lineHeight: "body",
      letterSpacing: "body",
    },
    h1: {
      variant: "text.heading",
      fontWeight: 500,
      fontSize: 5,
      marginTop: 0,
      marginBottom: "1.0rem",
    },
    h2: {
      variant: "text.heading",
      fontSize: 4,
      marginBottom: "1.0rem",
      borderLeftStyle: "solid",
      borderLeftWidth: 5, // 5px
      borderLeftColor: "primary",
      paddingLeft: 10, // 10px
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
      lineHeight: "body",
      marginBottom: "1.0rem",
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
      marginBottom: "1.0rem",
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
      marginBottom: "1.0rem",
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
      marginBottom: "1.0rem",
    },
    p: {
      margin: "0 0 1.0rem 0",

      "& + h2, & + h3, & + h4": {
        marginTop: "2.0rem",
      },
    },
    div: {
      "& + h2, & + h3, & + h4": {
        marginTop: "2.0rem",
      },
    },
    ul: {
      "& + h2, & + h3, & + h4": {
        marginTop: "2.0rem",
      },
    },
    a: {
      color: "link",
      textDecorationColor: "rgba(178, 0, 0, 0.3)", // transparentize(0.7, link-color),
      transition: "all 150ms cubic-bezier(0.215, 0.610, 0.355, 1.000)",

      "&:visited": {
        color: "visitedLink",
        textDecorationColor: "rgba(85, 26, 139, 0.3)", // transparentize(0.7, visited-link-color)
        transition: "all 150ms cubic-bezier(0.215, 0.610, 0.355, 1.000)",
      },

      "&:visited:hover": {
        color: "#4D177D", // shade(0.1, visited-link-color)
        textDecorationColor: "rgba(85, 26, 139, 0.5)", // transparentize(0.5, visited-link-color)
      },

      "&:visited:focus": {
        color: "#4D177D", // shade(0.1, visited-link-color)
        textDecorationColor: "#4D177D", // shade(0.1, visited-link-color)
      },

      "&:hover": {
        color: "A00000", // shade(0.1, link-text-color)
        textDecorationColor: "rgba(178, 0, 0, 0.5)", // transparentize(0.5, link-text-color)
      },

      "&:focus": {
        color: "A00000", // shade(0.1, link-text-color)
        textDecorationColor: "A00000", // shade(0.1, link-text-color)
      },
    },
    hr: {
      borderTopColor: "secondary",
      borderTopStyle: "solid",
      borderTopWidth: 1, // 1px
      margin: "1.5rem 0",
    },
  },
};

export default theme;
