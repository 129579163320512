exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-index-2-tsx": () => import("./../../../src/pages/index2.tsx" /* webpackChunkName: "component---src-pages-index-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-shishimai-2-tsx": () => import("./../../../src/pages/shishimai2.tsx" /* webpackChunkName: "component---src-pages-shishimai-2-tsx" */),
  "component---src-pages-shishimai-tsx": () => import("./../../../src/pages/shishimai.tsx" /* webpackChunkName: "component---src-pages-shishimai-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-shishimai-taikai-post-tsx": () => import("./../../../src/templates/shishimai-taikai-post.tsx" /* webpackChunkName: "component---src-templates-shishimai-taikai-post-tsx" */),
  "component---src-templates-taikai-post-tsx": () => import("./../../../src/templates/taikai-post.tsx" /* webpackChunkName: "component---src-templates-taikai-post-tsx" */)
}

